import Keycloak from "keycloak-js";

//@ts-ignore
const keycloakInstance = new Keycloak();

interface CallbackOneParam<T1 = void, T2 = void> {
  (param1: T1): T2;
}
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (): Promise<boolean> => {
  return keycloakInstance
    .init({ onLoad: "login-required", checkLoginIframe: false })

};

const UserName = (): string | undefined => {
    if (keycloakInstance.tokenParsed === undefined) return undefined;
    return keycloakInstance.tokenParsed.preferred_username;
}

const Token = (): string | undefined => {
    if (keycloakInstance.tokenParsed === undefined) return undefined;
    return keycloakInstance.token;
}

const updateToken = (successCallback: any) =>
  keycloakInstance.updateToken(5).then(successCallback).catch(doLogin);

const doLogin = keycloakInstance.login;

const LogOut = () => keycloakInstance.logout({ redirectUri: process.env.VUE_APP_KC_REDIRECT_URI });

const isLoggedIn = () => !!keycloakInstance.token;

const UserRoles = (): string[] | undefined => {
    if (keycloakInstance.resourceAccess === undefined) return undefined;
    return keycloakInstance.resourceAccess[`${process.env.VUE_APP_KC_CLIENT_ID}`].roles;
};

const UserApps = (): string[] | undefined => {
  const apps = [];
  //@ts-ignore
  if (keycloakInstance.tokenParsed.AIMS_ID != undefined) {
    apps.push('DMS');
  }
  //@ts-ignore
  if (keycloakInstance.resourceAccess != undefined && keycloakInstance.resourceAccess['prod-ems-frontend'] != null) {
    apps.push('EMS');
  } 
  if (keycloakInstance.resourceAccess != undefined && keycloakInstance.resourceAccess['prod-forms-frontend'] != null) {
    apps.push('Forms');
  } 

  return apps;
};

const KeyCloakService = {
  CallLogin: Login,
  GetUserName: UserName,
  GetAccesToken: Token,
  CallLogOut: LogOut,
  GetUserRoles: UserRoles,
  UpdateToken: updateToken,
  IsLoggedIn: isLoggedIn,
  GetUserApps: UserApps
};

export default KeyCloakService;