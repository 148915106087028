<template>
  <v-container fluid>
    <v-row justify="start" align="start">
      <v-col cols="12" xl="2" sm="5" md="5" v-for="(tile, index) in tiles" :key="index">
        <v-card
          elevation="3"
          class="pa-4 d-flex justify-center align-center"
          :style="{
            cursor: tile.disabled ? 'not-allowed' : 'pointer',
            height: '200px',
            opacity: tile.disabled ? 0.5 : 1,
          }"
          @click="!tile.disabled && openInNewTab(tile.url)"
        >
          <v-col cols="10" class="text-center">
            <v-card-title class="headline text-center" style="white-space: normal;">
              {{ tile.title }}
            </v-card-title>
            <v-card-subtitle
              v-if="tile.subtitle"
              class="headline text-center"
              style="white-space: normal;"
            >
              {{ tile.subtitle }}
            </v-card-subtitle>
          </v-col>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>


<script lang="ts">
  import { Options, Vue } from 'vue-class-component';
  import KeyCloakService from "./../security/KeycloakService";

  @Options({
    components: {}
  })
  export default class DashboardView extends Vue {
    private tiles = [
    ];

    private openInNewTab(url: string) {
      window.open(url, '_blank');
    }

    created() {
      let apps = KeyCloakService.GetUserApps();
            //@ts-ignore
      if (apps.includes("DMS")){
        //@ts-ignore
        this.tiles.push({ title: 'Document Management System', url: 'https://dms.portal.ahmadiyya.de' });
      }
      //@ts-ignore
      if (apps.includes("EMS")){
        //@ts-ignore
        this.tiles.push({ title: 'Expense Management System', url: 'https://expense.fra.ahmadiyya.de', subtitle: '(VPN benötigt)' });
      }
      //@ts-ignore
      if (apps.includes("Forms")){
        //@ts-ignore
        this.tiles.push({ title: 'Forms', url: 'https://forms.ahmadiyya.de' });
      }
      //@ts-ignore
      this.tiles.push({ title: 'Election Report System', url: 'https://ecs.portal.ahmadiyya.de'});
      //@ts-ignore
      this.tiles.push({ title: 'work.ahmadiyya.de', url: 'https://work.ahmadiyya.de'});
      //@ts-ignore
      this.tiles.push({ title: 'info.ahmadiyya.de', url: 'https://info.ahmadiyya.de'});
      //@ts-ignore
      this.tiles.push({ title: 'Murabbiyan Report System', url: 'https://info.ahmadiyya.de', disabled: true});
      //@ts-ignore
      this.tiles.push({ title: 'Jamaat Report System', url: 'https://info.ahmadiyya.de', disabled: true});
      //@ts-ignore
      this.tiles.push({ title: 'Local Amarat Report System', url: 'https://info.ahmadiyya.de', disabled: true});
    }
  }
</script>
